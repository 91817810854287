.banicon {
  color: rgb(215, 68, 68);
  font-size: 3rem
}

.enddiv {
  font-weight: 300;
  font-size: 1.2rem;
  margin: 5px 3rem;
}

.spacediv {
  margin: 5px 0;
}

.mt-5rem {
  margin-top: 5rem;
}

/* Slideshow */

.slideshow {
  left: 0;
  width: 100%;
  position: fixed;
  bottom: 3vh;
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  height: 5rem;
  width: auto;
  /* border-radius: 25px; */
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #000000;
}