@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('global.scss');

.App {
  text-align: center;
}
.contact-card {
  text-align: left;
  font-size: 1.2rem;
    border-radius: 10%;
    border: 2px solid black;
    padding: 1rem;
    margin: 1rem;
}

.sameLine {
  display: block;
}

.call-buton .cc-calto-action-ripple {
  z-index: 99999;
  position: fixed;
  right: 1rem;
  bottom: 8rem;
  background: #ec8b00;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  font-size: 2.2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 85vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}

.logo {
  width: 120px;
  height: 120px;
}

.button {
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button2 {
  background-color: white;
  color: black;
  border: 2px solid #008CBA;
}

.button2:hover {
  background-color: #008CBA;
  color: white;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pet-name {
  font-size: 2.5rem;
}

.pet-message {
  margin-top: 1rem;
}

.pet-contact {
  margin-top: 5rem;
  margin-bottom: 0.6rem;
}

.button-pet {
  width: 64%;
}

.m-1-5rem {
  margin: 1rem;
}

.mt-1rem {
  margin-top: 1rem;
}

.font-6rem {
  font-size: 6rem;
}

.item-name-tag {
  color: green;
}


.pet-header {
  width: 100vw;
  height: 8.5rem;
  /* background: #d2d2d2; */
  /* background: #d2d7e9; */
  padding: 1rem;
  position: relative;
}

.pet-name-block {
  position: absolute;
  bottom: 1.4rem;
  left: 1.7rem;
  text-align: left;
}

.pet-name-new {
  font-size: 2.2rem;
}

.pet-name-under {
  font-size: 0.9rem;
}

.social-strip {
  background: linear-gradient(to right, rgba(0,0,0,0.18), rgba(0,0,0,0.61));
}

.social-icn {
  color: white !important;
  opacity: 0.9;
}